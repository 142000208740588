import {Component, inject, OnInit} from '@angular/core';
import {
  AbstractUnitViewWithTabsComponent,
  ApplicationConfigService,
  createTabTableId,
  CurrentSessionService,
  DocumentSearchService,
  DocumentsTableView,
  DocumentView,
  StorageUnitsDatasource,
  StorageUnitSearchService,
  StorageUnitView,
  TableWithTabsViewMetadata,
  WebSocketNotificationsService
} from '|shared';
import {SettledDocumentsDataSource} from './settled-documents.datasource';
import {BehaviorSubject} from 'rxjs';
import {StorageUnitFillersDatasource} from './storage-unit-fillers.datasource';
import {StorageUnitFillerSearchService} from '../../../../../shared/src/lib/services/storage-unit-filler-search.service';
import {InternalNotificationMessageCode} from '|api/notification';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum SettledDocumentsView {
  STORED = 'stored',
  NOT_STORED = 'notStored',
  STORAGE_UNITS = 'storageUnits',
  RETENTION_YEAR_CHECK = 'retentionYearCheck',
  STORAGE_UNIT_FILLERS = 'storageUnitFillers',
}

interface SettledViewMetadata extends TableWithTabsViewMetadata<SettledDocumentsView> {
  tableView: DocumentsTableView;
}

@Component({
  selector: 'icz-settled-documents',
  templateUrl: './settled-documents.component.html',
  styleUrls: ['./settled-documents.component.scss'],
})
export class SettledDocumentsComponent extends AbstractUnitViewWithTabsComponent<SettledDocumentsView, SettledViewMetadata> implements OnInit {

  private documentSearchService = inject(DocumentSearchService);
  private storageUnitSearchService = inject(StorageUnitSearchService);
  private storageUnitFillerSearchService = inject(StorageUnitFillerSearchService);
  private webSocketNotificationsService = inject(WebSocketNotificationsService);
  private currentSessionService = inject(CurrentSessionService);
  private applicationConfigService = inject(ApplicationConfigService);

  private sufTab = {
    id: SettledDocumentsView.STORAGE_UNIT_FILLERS,
    label: 'Dávky plnění ukládacích jednotek',
  };

  override tabs$ = new BehaviorSubject([
    {
      id: SettledDocumentsView.NOT_STORED,
      label: 'Ukončené neuložené',
    },
    {
      id: SettledDocumentsView.STORAGE_UNITS,
      label: 'Ukládací jednotky',
    },
    {
      id: SettledDocumentsView.STORED,
      label: 'Vše uložené',
    },
    {
      id: SettledDocumentsView.RETENTION_YEAR_CHECK,
      label: 'Rok spuštění',
    },
  ]);

  protected viewsEnum = SettledDocumentsView;
  protected defaultView = SettledDocumentsView.NOT_STORED;

  readonly DocumentView = DocumentView;

  get isStorageUnitsView() {
    return this.tabView === SettledDocumentsView.STORAGE_UNITS;
  }

  get isStorageUnitFillersView() {
    return this.tabView === SettledDocumentsView.STORAGE_UNIT_FILLERS;
  }

  get tableView(): Nullable<DocumentsTableView> {
    return this.activeViewMetadata?.tableView;
  }

  get viewTableId(): Nullable<string> {
    return this.activeViewMetadata?.tableId;
  }

  override ngOnInit() {
    super.ngOnInit();
    const sufConfig = this.applicationConfigService.storageUnitFillerConfiguration;

    if (sufConfig) {
      this.currentSessionService.currentUserFunctionalPosition$.subscribe(currentFp => {
        if (currentFp && sufConfig.allowedFPCodes?.includes(currentFp.code)) {
          this.tabs$.next([...this.tabs$.value, this.sufTab]);
        }
      });
    }

    this.webSocketNotificationsService.getMessagesListener$([
      InternalNotificationMessageCode.STORAGE_UNIT_FILLER_SUCCESS,
      InternalNotificationMessageCode.STORAGE_UNIT_FILLER_STARTED,
      InternalNotificationMessageCode.STORAGE_UNIT_FILLER_ERROR])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(_ => {
        if (this.activeViewMetadata?.tabView === SettledDocumentsView.STORAGE_UNIT_FILLERS) {
          this.dataSource?.reload(false);
        }
      });
  }

  protected getViewMetadata(tabView: SettledDocumentsView, selectorValue: Nullable<number>): SettledViewMetadata {
    switch (tabView) {
      case SettledDocumentsView.NOT_STORED:
        return {
          tabView,
          dataSource: new SettledDocumentsDataSource(
            this.documentSearchService,
            {
              isUnitView: this.isUnitView,
              orgUnitId: selectorValue
            },
            [],
            false,
            false
          ),
          tableView: DocumentView.SETTLED_DOCUMENTS,
          tableId: createTabTableId(SettledDocumentsView.NOT_STORED + 'settled-documents'),
        };
      case SettledDocumentsView.STORAGE_UNITS:
        return {
        tabView,
        dataSource: new StorageUnitsDatasource(
          this.storageUnitSearchService,
          {
            isUnitView: this.isUnitView,
            orgUnitId: selectorValue
          }
        ),
        tableView: StorageUnitView.STORAGE_UNITS_TABLE,
        tableId: createTabTableId(SettledDocumentsView.STORAGE_UNITS + 'settled-documents'),
      };
      case SettledDocumentsView.STORED:
        return {
          tabView,
          dataSource: new SettledDocumentsDataSource(
            this.documentSearchService,
            {
              isUnitView: this.isUnitView,
              orgUnitId: selectorValue
            },
            [],
            true,
            false
          ),
          tableView: DocumentView.SETTLED_DOCUMENTS_STORED,
          tableId: createTabTableId(SettledDocumentsView.STORED + 'settled-documents'),
        };
      case SettledDocumentsView.RETENTION_YEAR_CHECK:
        return {
          tabView,
          dataSource: new SettledDocumentsDataSource(
            this.documentSearchService,
            {
              isUnitView: this.isUnitView,
              orgUnitId: selectorValue
            },
            [],
            false,
            true
          ),
          tableView: DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR,
          tableId: createTabTableId(SettledDocumentsView.RETENTION_YEAR_CHECK + 'settled-documents'),
        };
      case SettledDocumentsView.STORAGE_UNIT_FILLERS:
        return {
          tabView,
          dataSource: new StorageUnitFillersDatasource(this.storageUnitFillerSearchService),
          tableView: StorageUnitView.STORAGE_UNIT_FILLERS_TABLE,
          tableId: createTabTableId(SettledDocumentsView.STORAGE_UNIT_FILLERS + 'settled-documents'),
        };
    }
  }

  protected readonly StorageUnitView = StorageUnitView;
}
