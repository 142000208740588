import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '|shared';
import {SettledDocumentsRoutingModule} from './settled-documents-routing.module';
import {SettledDocumentsComponent} from './settled-documents.component';
import {DocumentSharedModule} from '../document-shared/document-shared.module';

@NgModule({
  declarations: [SettledDocumentsComponent],
  imports: [CommonModule, DocumentSharedModule, SharedModule, SettledDocumentsRoutingModule, ],
})
export class SettledDocumentsModule {}
