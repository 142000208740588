import {IczTableDataSource} from '@icz/angular-table';
import {StorageUnitFillerDto} from '|api/document';
import {StorageUnitFillerSearchService} from '|shared';

export class StorageUnitFillersDatasource extends IczTableDataSource<StorageUnitFillerDto> {
  constructor(storageUnitFillerSearchService: StorageUnitFillerSearchService) {
    super(searchParams => {
      return storageUnitFillerSearchService.findStorageUnitFillers(searchParams);
    });
  }
}
