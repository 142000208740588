@if (dataSource && tableView) {
  @if (isStorageUnitsView) {
    <icz-storage-units-table
      class="grow"
      [tableId]="viewTableId!"
      [dataSource]="dataSource"
      [viewType]="StorageUnitView.STORAGE_UNITS_TABLE"
    >
      <ng-container tableTitle>
        <ng-container *ngTemplateOutlet="tableTitle"></ng-container>
      </ng-container>
      <ng-container tableTabs>
        <ng-container *ngTemplateOutlet="tableTabs"></ng-container>
      </ng-container>
      <ng-container noDataLabel>
        <ng-container *ngTemplateOutlet="noDataLabel"></ng-container>
      </ng-container>
    </icz-storage-units-table>

  } @else if (isStorageUnitFillersView) {
    <icz-storage-unit-fillers-table
      class="grow"
      [tableId]="viewTableId!"
      [dataSource]="dataSource"
      [viewType]="StorageUnitView.STORAGE_UNIT_FILLERS_TABLE"
    >
      <ng-container tableTitle>
        <ng-container *ngTemplateOutlet="tableTitle"></ng-container>
      </ng-container>
      <ng-container tableTabs>
        <ng-container *ngTemplateOutlet="tableTabs"></ng-container>
      </ng-container>
      <ng-container noDataLabel>
        <ng-container *ngTemplateOutlet="noDataLabel"></ng-container>
      </ng-container>
    </icz-storage-unit-fillers-table>

  } @else {
    <icz-documents-table
      class="grow"
      [tableId]="viewTableId!"
      [viewType]="tableView"
      [openQuickPreview]="!isStorageUnitsView && !isStorageUnitFillersView"
      [isUnitView]="isUnitView"
      [dataSource]="dataSource">
      <ng-container tableTitle>
        <ng-container *ngTemplateOutlet="tableTitle"></ng-container>
      </ng-container>
      <ng-container tableTabs>
        <ng-container *ngTemplateOutlet="tableTabs"></ng-container>
      </ng-container>
      <ng-container noDataLabel>
        <ng-container *ngTemplateOutlet="noDataLabel"></ng-container>
      </ng-container>
    </icz-documents-table>
  }
}

<ng-template #tableTitle>
  <div class="row grow items-center">
    <icz-label [label]="isUnitView ? 'Útvar - Ukončené' : 'Ukončené'"></icz-label>
    @if (isUnitView) {
      <icz-form-autocomplete
        class="ml-16 table-view-selector"
        [options]="selectorOptions"
        [value]="activeSelectorValue"
        (valueChange)="selectorValueChanged($event)"
        [showValidationStatus]="false"
        [clearable]="false"
      ></icz-form-autocomplete>
    }
  </div>
</ng-template>

<ng-template #tableTabs>
  <icz-tabs
    class="grow"
    [tabs]="(tabs$ | async)!"
    [activeTab]="activeTab"
    [shouldNavigate]="false"
    (tabClick)="viewTabChanged($event)"
  ></icz-tabs>
</ng-template>

<ng-template #noDataLabel>
  <icz-label [label]="isStorageUnitsView ? 'Žádné ukládací jednotky' : 'Žádné ukončené dokumenty'"></icz-label>
</ng-template>
