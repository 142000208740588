import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IczRoutes} from '|shared';
import {SettledDocumentsComponent} from './settled-documents.component';

const routes: IczRoutes = [{ path: '', component: SettledDocumentsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettledDocumentsRoutingModule { }
